<template>
  <section class="career-agent-info">
    <div class="career-agent-info__content constrain-content--smaller">
      <!-- <span class="sub-title">What's a Career Agent?</span> -->
      <h2>Personalised job offers <br/>from your career agent.</h2>
      <p>Maybe you already have a dream company in mind, or are just curious about a specific tech branch. We are here to help you out. </p>
      <base-button @click.native="EbbaModal">Get job recommendations</base-button>
      <profile-card
        :avatar="avatar"
        name="Martin, 31"
        job_title="Senior Android Developer"
        job_count="8"
        :tags="['Flutter', 'React Native', 'Javascript', 'Android', 'CI/CD', 'Kubernetes']"
      />
      
    </div>
  </section>
</template>
<script>
import ModalMixin from '@/mixins/modal'

export default {
  mixins: [ModalMixin],
  components: {
      ProfileCard: () => import("@/components/profile-card/profile-card"),
  },
  data() {
    return {
        avatar: require("@/assets/avatar1.jpg"),
    };
  },
  mounted() {},
};
</script>
<style lang="scss">
.career-agent-info {
    background-color: #f7f7f7;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 25vh !important;
    padding-bottom: 5rem;
    text-align: center;

    @include sm {
        padding-top: 28vh !important;
    }
    
    .profile-card {
        max-width: 400px;
        margin-top: 2.5rem;
    }
}
</style>
